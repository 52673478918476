import React, { useEffect, useState } from "react";
import { useQuery } from '@apollo/client';
import SEO from '../components/seo/seo';
import Loading from '../components/loading/loading';
import GET_HOME from '../graphql/get-home';

export default function Page() {
  const [ homePage, setHomePage ] = useState();
  const [ seo, setSEO ] = useState();
  const { loading, data: pageData } = useQuery(GET_HOME, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!loading && pageData) {
      setHomePage(pageData.pages.nodes[0]);
      setSEO(pageData.pages.edges[0].node.seo);
    }
  }, [loading, pageData, homePage]);

  return (
    <>
      {loading ? (
        <>
          <Loading />
        </>
      ) :(
        <>
          <SEO
            title={seo?.title || 'Kinloch Golf Club'}
            description={seo?.description}
          />
          <video
            className="video-background"
            src={homePage?.homepageacf?.video}
            height="100vh"
            width="100vw"
            loop
            muted
            autoPlay
            playsInline
            disablePictureInPicture
            disableRemotePlayback
          >
            <source src={homePage?.homepageacf?.video} type="video/mp4" />
          </video>
        </>
      )}
    </>
  )
}